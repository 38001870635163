import React, { useState } from "react";
import { Link } from "gatsby";

import line from "../assets/png/resultLine.png";
import ProgressBar from "@ramonak/react-progress-bar";
import { config } from "../components";



import HelmetIndex from "../components/helmetIndex";
import { CalLogo, CalFooter } from "../components";


const SpendingBreakDownCard = ({ title, percent, color, space }) => {
    return (
        <div className="resultBox3">
            <div className="resultBox3Text">
                {title}
            </div>
            <div className="resultBox3Text2">{percent}%</div>
            <div className={`calculatorProgressBar ${space ? "space" : ""}`}>
                <ProgressBar completed={percent} maxCompleted={100} bgColor={color} customLabel=" " height="7px" />
            </div>


        </div>
    );
}



export default ({ location }) => {
    const storedStringSport = typeof window !== 'undefined' &&localStorage.getItem('sport');
    const storedObjectSport = JSON.parse(storedStringSport);
    const storedString = typeof window !== 'undefined' &&localStorage.getItem('sportResult');
    const storedObject = JSON.parse(storedString);
    
    

    const [data, setData] = useState({
        spend: "",
        tool: "",
        email: ""
    })
    const [dataError, setDataError] = useState({
        spend: false,
        tool: false,
        email: false
    })
    const [disabled, setDisabled] = useState(false)
    const [responseError, setResponseError] = useState("")

   



    const onOptionChangeSpend = e => {
        setData({ ...data, spend: e.target.value })
    }
    const onOptionChangeTool = e => {
        setData({ ...data, tool: e.target.value })
    }
    const submit = async (e) => {
        e.preventDefault()
        
        setDisabled(true)
        setResponseError("")
        setDataError({...dataError,
            spend: false,
            tool: false,
            email: false
        })
        const newError = { spend: false,
            tool: false,
            email: false}
      
        const emailValidateRegex = /^\S+@\S+\.\S+$/
        if (data.email === "" || !emailValidateRegex.test(data.email)) {
            newError.email = true
        }
        if (data.tool === "") {
            newError.tool = true
        }
        if (data.spend === "") {
            newError.spend = true
        }
       
        
        setDataError({
            ...dataError,
            ...newError
        })
        for (const key in newError) {
            if (newError[key] === true) {
              
                setResponseError("Please enter the email and answer the questions")
                setDisabled(false)
                return
            }else{
                
            }
        }
        // no erro call api
        // let newData = {...data, sportId: storedObject.sportId}
        let newData = {...data,...storedObject}

        const input = JSON.stringify(newData)
        // setDisabled(false)
        // return
        try {
            const response = await fetch(config.backendAPi + "/register/sportscalculatorpage3c", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: input
            });

           

            const data = await response.json();
           
            if (data?.status === 0 && data?.statusText === "successful") {
                setResponseError("Email sent! Check your spam folder if you haven’t received it")
              
            } else {
                setResponseError(data?.statusText ?? "Something went wrong!")
            }
            setDisabled(false)
        } catch (error) {
            
            setResponseError("Something went wrong!")
            setDisabled(false)
        }

        
        setDisabled(false)
    }

    return (

        <div className="has-background-white has-text-primary indexBody liftrocketCalcaulator">
            <HelmetIndex />
            <div className="calculatorBody">
                <CalLogo />
                <div className="resultContainer">
                    <div className="resultTitle">Your <span className="orange">Youth Sports Spend Calculator </span>Results </div>

                    <div className="resultBox">
                        <div className="resultBoxText">Total Estimated Spend Until College - {storedObject.totalYears} {storedObject.totalYears===1?"year":"years"} </div>
                        <div className="resultBoxText2">${storedObject?.totalSpend?.toLocaleString()}</div>
                    </div>

                    <div className="resultBox resultBox2">
                        <div>
                            <div className="resultBox2Text">
                                {storedObject.sport}
                            </div>

                            <div className="resultBox2Text2">
                                Your Athlete’s Sport
                            </div>

                        </div>
                        <img src={line} className="resultLine" alt="line" />
                        <div>
                            <div className="resultBox2Text">
                                {storedObjectSport.athleteAge}
                            </div>
                            <div className="resultBox2Text2">
                                Your Athlete’s Age
                            </div>

                        </div>
                    </div>

                    <div className="resultText">
                        Spend Breakdown
                    </div>
                    <div className="SpendingContainer">
                        <SpendingBreakDownCard title="Registration" color="#ff5f58" percent={storedObject.registration} />
                        <SpendingBreakDownCard title="Travel" color="#4d6177" percent={storedObject.travel} />

                    </div>
                    <div className="SpendingContainer">
                        <SpendingBreakDownCard title="Lessons, camp, coaching" color="#4d6177" percent={storedObject.lessons} space={true} />
                        <SpendingBreakDownCard title="Equipment" color="#F06E62" percent={storedObject.equipment} />

                    </div>



                    <div className="resultBox resultBox4">
                        <div className="resultBox4Text">How close is this to your spend? </div>

                        <div className="spendingSelect">

                            <div className="radioSelectContainer">
                                <input type="radio" name="spending" id="spendingLow" value="That’s too low" onChange={e => onOptionChangeSpend(e)} />

                                <label className="resultBox4Text2 custom-radio" htmlFor="spendingLow">


                                </label>
                                That’s too low

                            </div>


                            <div className="radioSelectContainer">
                                <input type="radio" name="spending" id="spendingHigh" value="That’s too high" onChange={e => onOptionChangeSpend(e)} />

                                <label className="resultBox4Text2 custom-radio" htmlFor="spendingHigh">


                                </label>
                                That’s too high

                            </div>
                            <div className="radioSelectContainer">
                                <input type="radio" name="spending" id="spendingMid" value="That sounds about right" onChange={e => onOptionChangeSpend(e)} />

                                <label className="resultBox4Text2 custom-radio" htmlFor="spendingMid">


                                </label>
                                That sounds about right

                            </div>








                        </div>



                    </div>


                    <div className="resultBox resultBox5">
                        <div className="resultBox4Text">What's the best tool to keep your child playing? Choose your top pick!</div>

                        <div className="toolSelect">

                            <div className="radioSelectContainer">
                                
                                <input type="radio" name="tool" id="toolKeep" value="Keep track of youth sports expenses in one place" onChange={e => onOptionChangeTool(e)} />
                                <label className="toolSelectLabel custom-radio" htmlFor="toolKeep"> </label>
                                <div className="toolSelectText2">
                                    Keep track of youth sports expenses in one place
                                </div>


                            </div>

                            <div className="radioSelectContainer">


                                <input type="radio" name="tool" id="toolFinance" value="Finance youth sports expenses when needed at low cost" onChange={e => onOptionChangeTool(e)} />
                                <label className="toolSelectLabel custom-radio" htmlFor="toolFinance"> </label>
                                <div className="toolSelectText2">
                                    Finance youth sports expenses when needed at low cost
                                </div>


                            </div>

                            <div className="radioSelectContainer">

                                <input type="radio" name="tool" id="toolSave" value="Save for youth sports expenses automatically in a high rate account" onChange={e => onOptionChangeTool(e)} />
                                <label className="toolSelectLabel custom-radio" htmlFor="toolSave"> </label>
                                <div className="toolSelectText2">
                                    Save for youth sports expenses automatically in a high rate account
                                </div>


                            </div>

                            <div className="radioSelectContainer">

                                <input type="radio" name="tool" id="toolEarn" value="Earn premium rewards for youth sports expenses" onChange={e => onOptionChangeTool(e)} />
                                <label className="toolSelectLabel custom-radio" htmlFor="toolEarn"> </label>
                                <div className="toolSelectText2">
                                    Earn premium rewards for youth sports expenses
                                </div>

                            </div>









                        </div>
                    </div>

                    <div className="testc-email-title">Earn $25 with your registration fee receipt</div>

                    <div>
                        <input placeholder="Enter email for instructions" className={dataError.email ? "calculatorFormInput calculatorFormInput2 testc-input error" : "calculatorFormInput calculatorFormInput2 testc-input"} value={data.email} onChange={e => setData({ ...data, email: e.target.value })} type="email" />
                    </div>
                    <div className="calculatorFormButtonContainer">
                        <button className="calculatorFormButton calculatorFormButton2" disabled={disabled} onClick={(e) => { submit(e) }}>
                        Submit 
                        </button>


                    </div>
                    {responseError===""? <div className="resultBoxText3">
                        <div> We take your privacy seriously.
                        Your information will never be shared
                        or used without your permission.</div>
                        <Link className="CalPrivatePolicy" to={"/privacypolicy"}> Privacy Policy.</Link>
                       
                        
                    </div>: <div className="resultBoxText7">
                        <div>{responseError}</div>
        
                       
                        
                    </div>}
                   
                    

                    <div className="upcomingContainer">
                        <div className="resultBoxText4">UPCOMING</div>
                        <div className="resultBoxText5">Stay tuned ...</div>
                        <div className="resultBoxText6">We will let you know when the LiftRocket youth sports spend
                            management system is available to help your family stay ahead of the game!
                        </div>
                    </div>




                </div>
            </div>

            <CalFooter />


        </div>
    );
};